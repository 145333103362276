/*
 * @Descripttion: 
 * @version: 
 * @Author: tina.cai
 * @Date: 2024-07-27 14:17:07
 * @LastEditors: tina.cai
 * @LastEditTime: 2024-07-28 15:50:12
 */

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index2',
    component: () => import(/* webpackChunkName: "tikmobileB" */ '@/views/homeB.vue')
  },
  {
    path: '/productList',
    name: 'productListB',
    component: () => import(/* webpackChunkName: "productListB" */ '@/views/productListB.vue')
  },
  {
    path: '/gameDetail/:id',
    name: 'payCheckoutB',
    component: () => import(/* webpackChunkName: "payCheckoutB" */ '@/views/payCheckoutB.vue')
  },
  {
    path: '/payConfirm/:gameId/:orderNo',
    name: 'payConfirmB',
    component: () => import(/* webpackChunkName: "payConfirmB" */ '@/views/payConfirmB.vue')
  },
  {
    path: '/payResult',
    name: 'payResultB',
    component: () => import(/* webpackChunkName: "payResultB" */ '@/views/payResultB.vue')
  },
  {
    path: '/rprivacy',
    name: 'rprivacy',
    component: () => import(/* webpackChunkName: "rprivacy" */ '@/views/rprivacy.vue')
  },
  {
    path: '/reula',
    name: 'reula',
    component: () => import(/* webpackChunkName: "reula" */ '@/views/reula.vue')
  },
  {
    path: '/raction',
    name: 'raction',
    component: () => import(/* webpackChunkName: "raction" */ '@/views/raction.vue')
  }
  
]

const routerB = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default routerB
