const triklet = {
  brandName: "TRIKLET",
  companyName: "Triklet",
  email: "support@triklet.com",
  copyRight: "Copyright © 2023 - 2025 Triklet Rights Reserved.",
  address: "ROOM 803, CHEVALIER HOUSE 45-51 CHATHAM ROAD SOUTH, TSIM SHA TSUI, KLN, HONG KONG",
  merchantId: "88934",
  key: "2cb9cc5ca0d19c250fd8877486aea87f",
  baseUrl: 'https://api.tikipay.co',
  logo: "https://tikipay-static-resource.oss-cn-shenzhen.aliyuncs.com/triklet.png"
};


const lipper = {
  brandName: "PT MAHADIKA SOLUSINDO TEKNOLOGI",
  companyName: "PT MAHADIKA SOLUSINDO TEKNOLOGI",
  email: "support@seavoucher.com",
  copyRight: "Copyright © 2023 - 2025 PT MAHADIKA SOLUSINDO TEKNOLOGI Rights Reserved.",
  address: "Ruko Permata Regency Blok D No. 37, Jl. Haji Kelik, Kel. Srengseng, Kec. Kembangan, Kota Adm. Jakarta Barat, Provinsi DKI Jakarta, Indonesia",
  merchantId: "88934",
  key: "2cb9cc5ca0d19c250fd8877486aea87f",
  baseUrl: 'https://api.tikipay.co',
  logo: "https://tikipay-static-resource.oss-cn-shenzhen.aliyuncs.com/seavoucher.png"
};

const pasarkartu = {
  brandName: "Pasarkartu",
  companyName: "Pasarkartu",
  email: "support@pasarkartu.com",
  copyRight: "Copyright © 2023 - 2025 Pasarkartu Rights Reserved.",
  address: "Ruko Permata Regency Blok D No. 37, Jl. Haji Kelik, Kel. Srengseng, Kec. Kembangan, Kota Adm. Jakarta Barat, Provinsi DKI Jakarta, Indonesia",
  merchantId: "88934",
  key: "2cb9cc5ca0d19c250fd8877486aea87f",
  baseUrl: 'https://api.tikipay.co',
  logo: "https://tikipay-static-resource.oss-cn-shenzhen.aliyuncs.com/seavoucher.png"
};


let type = {}
console.log("NODE_ENV",process.env.NODE_ENV)
console.log("VUE_APP_FLAG",process.env.VUE_APP_FLAG)
if (process.env.VUE_APP_FLAG == "triklet") {
  type = triklet
} else if (process.env.VUE_APP_FLAG == "pasarkartu") {
  type = pasarkartu
} else {
  type = lipper
}
export default type
