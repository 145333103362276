<template>
  <div id="app" :class="[isMobile ? 'mobile':'pc', 'white-theme']">
    <div
      :class="[isGameDetailPage && isMobile ? 'components-layout game-detail' : 'components-layout', isGameDetailPage && 'game-detail']"
      ref="layout"
    >
      <a-layout>
        <a-layout-header>
          <headerVue :key="componentKey"/>
        </a-layout-header>
        <a-layout-content>
          <router-view @refresh="handleRefresh"/>
        </a-layout-content>
        <a-layout-footer>
          <footerVue :key="componentKey"/>
        </a-layout-footer>
      </a-layout>
    </div>
  </div>
</template>

<script>
import headerVue from './views/headerB.vue';
import footerVue from './views/footerB.vue';
export default {
  components: { footerVue, headerVue },
  computed: {
    isGameDetailPage() {
      return this.$route.path.includes('gameDetail');
    },
  },
  data() {
    return {
      isMobile: false,
      componentKey: 0,
    }
  },
  mounted() {
  },
  methods: {
    getQueryValue(queryName) {
      var query = decodeURI(window.location.search.substring(1))
      var vars = query.split('&')
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] == queryName) {
          return pair[1]
        }
      }
      return null
    },
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },

    handleRefresh() {
      this.componentKey += 1;
    }
  }
}
</script>

<style lang="scss">
@import './assets/css/reset.scss';
// html.mobile {
//   font-size: 13.3333vw;
//   background: #1a1a1a;
// }
#app {
  font-family: Helvetica, Avenir, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  &.pc {
    min-width: 1200px;
    overflow-x: auto;
  }
}
</style>

<style lang="scss" scoped>
.components-layout {
  .ant-layout-header {
    height: 86px;
    background: #222222;
    padding: 0 16px;
  }
  .ant-layout-footer {
    background: #1a1a1a;
    color: #fff;
    padding: 66px 16px 62px;
  }
  .ant-layout-content {
    background: #111111;
    color: #fff;
    min-height: calc(100vh - 68px);
  }
}

.white-theme {
  .components-layout {
    .ant-layout-header {
      height: 68px;
      background: #FFFFFF;
      color: #222222;
      padding: 0 16px;
    }
    .ant-layout-footer {
      background: #FFFFFF;
      color: #222222;
      padding: 66px 16px 62px;
    }
    .ant-layout-content {
      background: rgba(180,180,180, 0.16);
      color: #222222;
      min-height: calc(100vh - 68px);
    }
  }
}
</style>
